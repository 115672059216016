import { useMediaQuery, useTheme } from "@material-ui/core";
import FilterAndSrt from "./MobileFilterSort";
import React, { useEffect, useMemo, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ProductNav } from "../AllNavCategories/AllNavCategories";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import {
  fetchAllCatgoryFils,
  fetchProductsByBrands,
  // fetchProducts
} from "../hooks/useProducts";
import Products from "../Products/Products";
import { axiosIntance } from "../config";
const ProductPage = () => {
  const queryClient = useQueryClient();
  const { categoryId, name } = useParams();
  // console.log(categoryId);
  const history = useHistory();
  const theme = useTheme();
  const { data } = useQuery(["filters", categoryId], () =>
    fetchAllCatgoryFils(categoryId)
  );
  const chiki = queryClient.getQueryData(["filters", categoryId]);
  // ! use this cleanup function for resetting filters if necessary
  useEffect(() => {
    return () => {
      console.log("want to clean this");
      async function clearFilters() {
        const res = await axiosIntance.put(
          `/category/updateFils?categoryId=${categoryId}`,
          { brands: [], rating: "", sortBy: "", discount: "", page: "" }
        );
      }
      queryClient.setQueryData(["filters", categoryId], (current) => ({}));
      clearFilters();
    };
  }, []);
  // ! use this cleanup function for resetting filters if necessary
  const farray =
    // chiki &&
    useMemo(
      () =>
        Object?.keys(chiki ? chiki : {})
          .map((item, i) => chiki[item])
          .flat()
          .filter((item) => item !== null && item !== ""),
      [chiki]
    );
  const preventPushing = useRef(0);
  useEffect(() => {
    console.log(farray);
    farray?.length === 0 &&
      preventPushing.current > 2 &&
      history.push(`/products/${name}/${categoryId}`);
    preventPushing.current += 1;
    // console.log(preventPushing.current);
    const url =
      farray?.length !== 0
        ? farray?.map((item, i) => {
            if (item.includes("★")) return `rating=${item.match(/\d+/)[0]}&`;
            if (item.includes("%")) return `discount=${item.match(/\d+/)[0]}&`;
            if (item.includes("sortBy"))
              return `sortBy=${item?.match(/\d+/)[0]}&`;
            if (item.includes("page")) return `page=${item?.match(/\d+/)[0]}&`;
            return `brand${i}=${item}&`;
          })
        : null;
    farray?.length !== 0 &&
      history.push(`/products/${name}/${categoryId}?${url?.join("")}`);
  }, [farray]);
  //! pushing filters

  const mutateProductsByBrands = useMutation(fetchProductsByBrands, {
    onSuccess: (newData) =>
      queryClient.setQueryData(["products", categoryId], (current) => newData),
  });
  const mutateBack = useMutation(
    async () => {
      try {
        const res = await axiosIntance.get(
          `/product/getProductByCategory/${categoryId}?sortBy=""`
          // ?limit=${7}&page=${1}`
        );
        console.log("mutate back fired");
        return res.data;
      } catch (error) {
        return console.log(error);
      }
    },
    {
      onSuccess: (newData) =>
        queryClient.setQueryData(
          ["products", categoryId],
          (current) => newData
        ),
    }
  );
  const d = useLocation().search;
  const query = useMemo(() => new URLSearchParams(d), [d]);
  const preventMutateBackForFirstTime = useRef(0);

  useEffect(() => {
    let brands = [];
    let rating = [];
    let discount = [];
    let sortBy = [];
    let page = [];
    for (const [name, value] of query) {
      if (name.includes("brand") && value) brands.push(value);
      if (name.includes("rating")) rating.push(value);
      if (name.includes("discount")) discount.push(value);
      if (name.includes("sortBy") && value) sortBy.push(value);
      if (name.includes("page") && value) page.push(value);
      // a.push(value);
      // console.log(value.replace(/ /g,'').toLowerCase());
    }
    // console.log(brands);
    preventMutateBackForFirstTime.current > 1 &&
      brands.length === 0 &&
      rating.length === 0 &&
      discount.length === 0 &&
      sortBy.length === 0 &&
      page.length === 0 &&
      mutateBack.mutateAsync(categoryId);
    preventMutateBackForFirstTime.current += 1;
    // console.log(preventMutateBackForFirstTime.current);
    // console.log("fired");
    // query.values && console.log(a);
    // console.log(a);
    (brands.length !== 0 ||
      rating.length !== 0 ||
      discount.length !== 0 ||
      sortBy.length !== 0 ||
      page.length !== 0) &&
      mutateProductsByBrands.mutateAsync({
        brands: brands.join(","),
        rating,
        discount,
        categoryId,
        sortBy,
        page,
      });
  }, [query]);
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <div>
      {matches ? <ProductNav history={history} title="chikki" /> : <Header />}
      {matches && <FilterAndSrt matches={matches} />}
      <Products mutateProductsByBrands={mutateProductsByBrands} />
      <Footer />
    </div>
  );
};

export default React.memo(ProductPage);
// const farray =
//   // chiki &&
//   useMemo(
//     () =>
//       Object?.keys(chiki ? chiki : {})
//         .map((item, i) => {
//           if (item !== "_id" && item !== "__v" && item !== "category")
//             return chiki[item];
//           return null;
//         })
//         .flat()
//         .filter((item) => item !== null && item !== ""),
//     [chiki]
//   );
