import {
  AppBar,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
} from "@material-ui/core";
import { navdata } from "../Components/Header/HeaderBottom";
import { ArrowBack, Search, ShoppingBasket } from "@material-ui/icons";
import { Link } from "react-router-dom";

const AllNavCategories = () => {
  return (
    <>
      <div>
        <ProductNav title="All&nbsp;Categories" />
      </div>
      <Grid container>
        {navdata.map((item, i) => (
          <Grid
            key={i}
            item
            xs={4}
            sm={3}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="flexStyle"
              style={{
                margin: "7px 0",
                width: "clamp(4rem,30vw,10rem)",
                flexDirection: "column",
                justifyContent: "center",
                padding: 10,
                boxShadow: "0 1px 6px 0 rgb(32 33 36 / 28%)",
              }}
            >
              <img src={item.image} alt="" width="100%" />
              <p style={{ fontSize: 13 }}>{item.title}</p>
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export const ProductNav = ({ title }) => {
  const useStyles = makeStyles({
    appBar: {
      background: "#2874F0",
      position: "sticky",
      //   padding: "10px 0",
    },
    icon: {
      color: "white",
    },
  });
  const pStyle = { marginLeft: 10, fontWeight: 400 };
  const classes = useStyles();
  return (
    <AppBar className={classes.appBar} elevation={0}>
      <Toolbar
        style={{ justifyContent: "space-between", minHeight: 53 }}
        className="flexStyle"
      >
        <div className="flexStyle">
          <IconButton
            size="small"
            className={classes.icon}
            component={Link}
            to="/"
          >
            <ArrowBack />
          </IconButton>
          <p style={pStyle}>{title}</p>
        </div>
        <div className="flexStyle">
          <IconButton className={classes.icon}>
            <Search />
          </IconButton>
          <IconButton className={classes.icon}>
            <ShoppingBasket />
          </IconButton>
          {/* <ButtonBase> */}
          <p style={pStyle}>Login</p>
          {/* </ButtonBase> */}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default AllNavCategories;
