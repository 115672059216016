import { AppBar, Toolbar } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import "./styles.css";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";

export default function SamsungHead() {
  return (
    <AppBar position="sticky">
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "white",
        }}
      >
        <div style={{ flex: "0.14", color: "black" }}>
          <h2>samsung</h2>
        </div>
        <div style={{ flex: "0.56" }}>
          <ul style={{ display: "flex", listStyle: "none" }}>
            <li>Mobile</li>
            <li>TV & AV</li>
            <li>Home appliances</li>
            <li>display</li>
            <li>offers</li>
            <li>compting</li>
          </ul>
        </div>

        <div style={{ display: "flex", flex: "0.3", alignItems: "center" }}>
          <div style={{ marginRight: 60 }}>
            <ul style={{ display: "flex", listStyle: "none" }}>
              <li>Explore</li>
              <li>Support</li>
              <li>business</li>
            </ul>
          </div>
          <div>
            <ul style={{ display: "flex", listStyle: "none" }}>
              <li>
                <Search />
              </li>
              <li>
                <AccountCircleOutlinedIcon />
              </li>
              <li>
                <ShoppingCartOutlinedIcon />
              </li>
            </ul>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}
