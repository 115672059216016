import axios from "axios";
import { useQuery } from "react-query";
import { API, axiosIntance } from "../config";

export const fetchProducts = async ({ queryKey }) => {
  const [_key, categoryId] = queryKey;
  console.log(queryKey);
  try {
    const res = await axios.get(
      `${API}/product/getProductByCategory/${categoryId}`
      // ?limit=${7}&page=${1}`
    );
    return res.data;
  } catch (error) {
    return console.log(error);
  }
};
export const fetchBrands = async ({ queryKey }) => {
  try {
    const { categoryId } = queryKey[1];
    const res = await axios.get(`${API}/product/getBrands/${categoryId}`);
    // console.log(res.data);
    return res.data;
  } catch (error) {
    return console.log(error);
  }
  // console.log(queryKey);
};
// export const useProducts = (categoryId, sortBy) => {
//   return useQuery({
//     queryKey: ["products", categoryId, sortBy],
//     queryFn: fetchProducts,
//   });
// };

//
const fetchCarousels = async () => {
  const res = await axios.get(`${API}/carousel/getAll`);
  return res.data;
};
export const fetchAllCatgoryFils = async (categoryId) => {
  const res = await axiosIntance.get(
    `/category/getAllCategoryFils?categoryId=${categoryId}`
  );
  return res.data;
};

export const fetchProductsByBrands = async ({
  rating,
  brands,
  discount,
  categoryId,
  sortBy,
  page,
}) => {
  // const { brands } = queryKey[1];
  // console.log(rating, discount, brands);
  const res = await axios.get(
    `${API}/product/filterByBrands?categoryId=${categoryId}&brands=${brands}&rating=${rating}&sortBy=${sortBy}&page=${page}`
  );
  console.log("mutate by brands fired");
  return res.data;
};

export const useMultiItemCarousels = () => {
  return useQuery("carousels", () => fetchCarousels());
};
// export function useFetchBrands(categoryId) {
//   // console.log(categoryId);
//   return useQuery(categoryId && ["brands", categoryId], (categoryId) =>
//     fetchBrands(categoryId)
//   );
// }
// export const useFetchProductsByBrands = (brands) => {
//   // const queryClient = useQueryClient();
//   return useQuery("filter", () => fetchProductsByBrands(brands));
// };
