import {
  makeStyles,
  Slide,
  Dialog,
  ListItem,
  ListItemText,
  List,
  Radio,
} from "@material-ui/core";
import React from "react";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    bottom: 0,
    height: "36vh",
    padding: theme.spacing(0.3),
  },
}));
function FullScreenDialog({ open, handleClose }) {
  const classes = useStyles();
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      classes={{ paperFullScreen: classes.paper }}
    >
      <List
        disablePadding
        subheader={
          <ListItem className="grayBorderBottom">
            <ListItemText
              primary="SORT BY"
              disableTypography
              style={{ color: "var(--blue)", fontWeight: 500 }}
            />
          </ListItem>
        }
      >
        <LItem value="popularity" title="popularity" />
        <LItem value="PLtoH" title="Price -- Low to High" />
        <LItem value="PHtoL" title="Price -- High to Low" />
        <LItem value="NFirst" title="Newest First" />
      </List>
    </Dialog>
  );
}
function LItem({ title, value }) {
  const [selectedValue, setSelectedValue] = React.useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <ListItem button>
      <ListItemText
        primary={title}
        disableTypography
        style={{ fontSize: 15, fontWeight: 640 }}
      />
      <Radio
        style={{ padding: 0 }}
        size="small"
        checked={selectedValue === value}
        onChange={handleChange}
        value={value}
        name="radio-button-demo"
        inputProps={{ "aria-label": "popularity" }}
      />
    </ListItem>
  );
}
export default React.memo(FullScreenDialog);
