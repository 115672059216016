import {
  Breadcrumbs,
  Hidden,
  Typography,
  useMediaQuery,
  Chip,
  Box,
  Button,
} from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import NestedList from "./NestedList";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import React, { useMemo, useState } from "react";
// import { filters } from "../data";
import { Link, useParams, useLocation } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import { fetchBrands } from "../hooks/useProducts";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ShowCaseProducts from "./ShowCaseProducts";
import { axiosIntance } from "../config";

// import { blue } from "@material-ui/core/colors";

const Products = () => {
  const { categoryId } = useParams();
  const { data } = useQuery(["totalPages", categoryId], () =>
    axiosIntance.get(`/product/getTotalPages?categoryId=${categoryId}`)
  );
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const queryClient = useQueryClient();
  const handlePageChange = (e, page) => {
    mutatePagination.mutateAsync(page);
  };
  const mutatePagination = useMutation(
    async (page) => {
      const res = await axiosIntance.put(
        `/category/updateFils?categoryId=${categoryId}`,
        { page: page + "page" }
      );
      return res.data;
    },
    {
      onMutate: (page) => {
        queryClient.setQueryData(["filters", categoryId], (current) => ({
          ...current,
          page: page + "page",
        }));
      },
    }
  );
  return (
    <div
      style={{
        background: "#F1F3F6",
        padding: !matches && 10,
        display: "flex",
      }}
    >
      <Hidden xsDown>
        <div style={{ flex: !matches && 0.21 }}>
          <ProductLeft />
        </div>
      </Hidden>
      {matches ? (
        <ShowCaseProducts />
      ) : (
        <div style={{ flex: matches ? 1 : 0.79 }}>
          <ProductRight />
          <Box display="flex" justifyContent="center" my={3}>
            <Pagination
              count={data?.data}
              size="medium"
              onChange={(e, page) => handlePageChange(e, page)}
            />
          </Box>
        </div>
      )}
    </div>
  );
};

const ProductLeft = () => {
  const queryClient = useQueryClient();
  // const { status, data } = useProducts();
  // let productsBrands = [];
  // const findBrands = () => {
  //   return data?.map(
  //     (item) =>
  //       productsBrands.includes(item.brand) && productsBrands.push(item.brand)
  //   );
  // };
  const { categoryId, name } = useParams();
  const { data } = useQuery(["brands", { categoryId }], fetchBrands);
  const filters = useMemo(
    () => [
      { title: "brand", child: data && data.brands },
      {
        title: "customer ratings",
        child: ["4★ & above", "3★ & above", "2★ & above", "1★ & above"],
      },
      // {
      //   title: "offers",
      //   child: ["Buy More, Save More", "No Cost EMI", "Special Price"],
      // },
      {
        title: "discount",
        child: [
          "10% or more",
          "20% or more",
          "30% or more",
          "40% or more",
          "50% or more",
          "60% or more",
          "70% or more",
          "80% or more",
          "90% or more",
          "10% and below",
        ],
      },
      // { title: "color", child: [] },
    ],
    [data]
  );
  async function clearFilters() {
    const res = await axiosIntance.put(
      `/category/updateFils?categoryId=${categoryId}`,
      { brands: [], rating: "", sortBy: "", discount: "", page: "" }
    );
    queryClient.setQueryData(["filters", categoryId], (current) => ({
      brands: [],
      rating: "",
      sortBy: "",
      discount: "",
      page: "",
    }));
  }
  const chiki = queryClient.getQueryData(["filters", categoryId]);
  const farray =
    // chiki &&
    useMemo(
      () =>
        Object?.keys(chiki ? chiki : {})
          .map((item, i) => chiki[item])
          .flat()
          .filter((item) => item !== null && item !== ""),
      [chiki]
    );
  // const handleDelete = (chipToDelete) => () => {
  //   setChipData((chips) => chips.filter((chip) => chip !== chipToDelete));
  // };

  return (
    <div style={{ marginRight: 7, background: "white" }}>
      <Box
        display="flex"
        borderBottom="1px solid lightgray"
        justifyContent="space-between"
        padding="13px"
      >
        <p
          style={{
            fontWeight: 500,
            fontSize: 18,
          }}
        >
          Filters
        </p>
        <Button
          onClick={clearFilters}
          style={{
            textTransform: "capitalize",
            background: "var(--blue)",
            fontWeight: 500,
            color: "white",
          }}
        >
          clear all
        </Button>
      </Box>
      <div style={{ listStyle: "none", display: "flex", flexWrap: "wrap" }}>
        {farray.map((data) => {
          return (
            <li key={data}>
              <Chip
                label={data}
                onDelete={() => {}}
                style={{ margin: 5 }}
                clickable
                // className={classes.chip}
              />
            </li>
          );
        })}
      </div>
      {filters.map((item, i) => (
        <NestedList item={item} categoryId={categoryId} name={name} key={i} />
      ))}
    </div>
  );
};

const ProductRight = () => {
  return (
    <>
      <div style={{ background: "white", padding: 10 }}>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link color="inherit" to="/">
            Home
          </Link>
          <Link color="inherit" to="/">
            Home
          </Link>
          <Typography color="textPrimary">Breadcrumb</Typography>
        </Breadcrumbs>
        <CusTabs />
      </div>
    </>
  );
};

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
  },
  tab: {
    minWidth: "50px",
    textTransform: "capitalize",
    fontWeight: "500",
    fontSize: "14px",
    color: "black",
    "&.Mui-selected": {
      // outline: "none",
      fontWeight: "600",
      color: "var(--blue)",
    },
  },
});

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function CusTabs() {
  const queryClient = useQueryClient();
  const { categoryId } = useParams();
  const chiki = queryClient.getQueryData(["filters", categoryId]);
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const mutateSortBy = useMutation(
    async (dat) => {
      const res = await axiosIntance.put(
        `/category/updateFils?categoryId=${categoryId}`,
        { sortBy: dat + "sortBy" }
      );
      return res.data;
    },
    {
      onMutate: (newDatssa) => {
        queryClient.setQueryData(["filters", categoryId], (current) => ({
          ...current,
          sortBy: newDatssa + "sortBy",
        }));
      },
    }
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue.toString());
    mutateSortBy.mutateAsync(newValue.toString());
  };
  // useEffect(() => {
  //   console.log("sortValue", value);
  // }, [value]);
  const d = useLocation().search;
  const query = useMemo(() => new URLSearchParams(d), [d]);
  const tab = query.get("sortBy");
  // const tab = Number(chiki?.sortBy.match(/\d+/)[0]);
  return (
    <>
      <div className={classes.root}>
        <h4 style={{ marginRight: 20 }}>sortBy</h4>
        <Tabs
          value={tab ? Number(tab) : 0}
          onChange={handleChange}
          textColor="primary"
          variant="scrollable"
          // indicatorHeight="20px"
          TabIndicatorProps={{
            style: {
              // height: "10px",
              backgroundColor: "var(--blue)",
              bottom: 10,
            },
          }}
        >
          <Tab label="Popularity" className={classes.tab} />
          <Tab label="Price -- Low to High" className={classes.tab} />
          <Tab label="Price -- High to Low" className={classes.tab} />
          <Tab label="Newest First" className={classes.tab} />
        </Tabs>
      </div>

      <TabPanel value={value} index={0}>
        <ShowCaseProducts num={value} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ShowCaseProducts num={value} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ShowCaseProducts num={value} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ShowCaseProducts num={value} />
      </TabPanel>
    </>
  );
}

export default React.memo(Products);
