import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  makeStyles,
  useTheme,
  useMediaQuery,
  Select,
  MenuItem,
  InputBase,
} from "@material-ui/core";
import {
  AddCircleOutline,
  Archive,
  Delete,
  RemoveCircleOutline,
  Star,
  VerifiedUserTwoTone,
} from "@material-ui/icons";
import { useState } from "react";
import Header from "../Components/Header/Header";
export function Truncate(str, value) {
  return str.length > 10 ? str.substring(0, value ? value : 48) + "..." : str;
}
const CartPage = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles({
    button: {
      background: "var(--orange)",
      height: 50,
      width: 250,
      fontWeight: 600,
      fontSize: 15,
      color: "white",
      "&:hover": {
        color: "var(--blue)",
      },
    },
    secondary: {
      color: "var(--blue)",
      fontWeight: 550,
    },
    primary: {
      fontWeight: 550,
      fontSize: 22,
    },
    margin: {
      border: "1px solid #ced4da",
      display: "flex",
      borderRadius: 4,
      alignItems: "center",
    },
  });

  const classes = useStyles();
  const [quantity, setQuantity] = useState(1);
  const handleChange = (event) => {
    setQuantity(event.target.value);
  };

  return (
    <div style={{ background: "var(--backgroundGray)" }}>
      <Header />
      <Box
        display="flex"
        flexDirection={matches ? "column" : "row"}
        justifyContent="center"
        pt={3}
      >
        <div
          style={{
            background: "white",
            flex: matches ? 1 : 0.63,
            boxShadow: !matches && "0 1px 1px 0 rgb(0 0 0 / 20%)",
            height: "100%",
          }}
        >
          <Box padding={2} display="flex" justifyContent="space-between">
            <Box fontSize={18} fontWeight={500}>
              <p>My cart(1)</p>
            </Box>
            <Box
              display="flex"
              fontSize={14}
              fontWeight={500}
              color="var(--gray)"
            >
              <p>Deliver to</p>
              <p>Enter delivery pincode</p>
              <p>Check</p>
            </Box>
          </Box>
          <Divider />
          <Box
            padding={2}
            display="flex"
            flexDirection={matches ? "row-reverse" : "row"}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              flex={0.2}
            >
              <Box pt={!matches ? 3 : 0} pb={4} width="clamp(5rem,18vw,7.5rem)">
                <img
                  src="https://rukminim1.flixcart.com/image/224/224/kl9rssw0/computer/o/g/w/asus-original-imagyfp899keyhq2.jpeg?q=90"
                  width="100%"
                  alt=""
                />
                {matches && (
                  <div className={classes.margin}>
                    <p style={{ marginLeft: 10, marginRight: 7 }}>Qty: </p>
                    <Select
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      value={quantity}
                      onChange={handleChange}
                      input={<InputBase />}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={30}>more</MenuItem>
                    </Select>
                  </div>
                )}
              </Box>
              {!matches && (
                <Box display="flex" alignItems="center">
                  <RemoveCircleOutline />
                  <p
                    className="grayBorder"
                    style={{
                      display: "grid",
                      placeItems: "center",
                      width: 60,
                      height: 30,
                      margin: "0 10px",
                    }}
                  >
                    1
                  </p>
                  <AddCircleOutline />
                </Box>
              )}
            </Box>
            <Box
              flex={0.8}
              display="flex"
              flexDirection={matches ? "column" : "row"}
            >
              <Box flex={0.62}>
                <Box pb={1}>
                  <p
                    style={{
                      fontSize: "clamp(0.7rem,2.5vw,1rem)",
                      fontWeight: 500,
                    }}
                  >
                    {Truncate(
                      "ASUS Athlon Dual Core 3050U - (4 GB/1 TB HDD/Windows 10 Home) M515DA-EJ002TS Thin and Light Laptop"
                    )}
                  </p>
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: "clamp(0.7rem,2vw,1rem)",
                      color: "var(--gray)",
                      marginTop: 5,
                      // whiteSpace: "pre-wrap",
                    }}
                  >
                    15.6 inch, Transparent Silver, 1.80 kg, With MS Office
                  </p>
                </Box>
                <Box pb={2} display="flex" alignItems="center">
                  {matches ? (
                    <>
                      <div
                        className="flexStyle"
                        style={{
                          background: "#26A541",
                          color: "white",
                          fontSize: 13,
                          padding: "2px 6px",
                          borderRadius: 10,
                        }}
                      >
                        <span>4.4</span>
                        <Star style={{ fontSize: 13 }} />
                      </div>
                      <p style={{ fontSize: 13, margin: "0 5px" }}>(7071)</p>
                    </>
                  ) : (
                    <p style={{ color: "var(--gray)", fontSize: 14 }}>
                      Seller:TBL Online
                    </p>
                  )}
                  <img
                    src="https://static-assets-web.flixcart.com/www/linchpin/fk-cp-zion/img/fa_62673a.png"
                    alt=""
                    width={matches ? 50 : 70}
                  />
                </Box>
                <Box pb={4}>
                  <span style={{ fontSize: 18, fontWeight: 600 }}>₹25,999</span>
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      textDecoration: "line-through",
                      color: "gray",
                      marginLeft: 13,
                    }}
                  >
                    ₹29,495
                  </span>
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      color: "green",
                      marginLeft: 13,
                    }}
                  >
                    11% off
                  </span>
                </Box>
                {!matches && (
                  <Box>
                    <Button variant="contained">save for later</Button>
                    <Button variant="contained">remove</Button>
                  </Box>
                )}
              </Box>
              <Box flex={0.37}>
                <p style={{ fontSize: 14, marginBottom: 3 }}>
                  Delivery by Sun Jun 6 | Free₹40
                </p>
                <p style={{ fontSize: 12, color: "var(--gray)" }}>
                  7 Days Replacement Policy
                </p>
              </Box>
            </Box>
          </Box>
          {!matches && (
            <Box
              padding={2}
              display="flex"
              justifyContent="flex-end"
              boxShadow="rgba(0,0,0,0.1) 0px -2px 10px 0"
            >
              <Button variant="contained" className={classes.button}>
                Place order
              </Button>
            </Box>
          )}
        </div>
        {matches && (
          <List
            disablePadding
            style={{
              display: "flex",
              background: "white",
              borderTop: "1px solid lightgray",
              marginBottom: 20,
            }}
          >
            <ListItem
              button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Archive style={{ fontSize: 17, color: "var(--gray)" }} />
              <ListItemText
                disableTypography
                style={{
                  flex: 0.5,
                  fontSize: 14,
                  fontWeight: 500,
                  marginLeft: 10,
                }}
                primary="Save for later"
              />
            </ListItem>
            <ListItem
              button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Delete style={{ fontSize: 17, color: "var(--gray)" }} />
              <ListItemText
                disableTypography
                primary="Remove"
                style={{
                  flex: 0.5,
                  fontSize: 14,
                  marginLeft: 10,
                  fontWeight: 500,
                }}
              />
            </ListItem>
          </List>
        )}
        <Box
          flex={matches ? 1 : 0.3}
          ml={!matches ? 2 : 0}
          style={{ background: "white" }}
        >
          <Box boxShadow="0 1px 1px 0 rgb(0 0 0 / 20%)">
            <List
              disablePadding
              subheader={
                <>
                  <ListSubheader component="div" id="nested-list-subheader">
                    <p
                      style={{
                        fontSize: "clamp(0.7rem,3vw,1rem)",
                        fontWeight: 700,
                        textTransform: "uppercase",
                      }}
                    >
                      price details
                    </p>
                  </ListSubheader>
                  <Divider />
                </>
              }
            >
              <Box p={2}>
                <ListItem>
                  <ListItemText primary="Price (1 item)" />
                  <ListItemSecondaryAction>₹32,990</ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemText primary="Discount" />
                  <ListItemSecondaryAction>− ₹5,000</ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemText primary="Delivery Charges" />
                  <ListItemSecondaryAction>FREE</ListItemSecondaryAction>
                </ListItem>
                <Box pt={2} pb={2} ml={2} mr={2}>
                  <ListItem
                    style={{
                      borderTop: "1px dashed gray",
                      borderBottom: "1px dashed gray",
                      padding: "15px 0",
                    }}
                  >
                    <ListItemText
                      disableTypography
                      style={{ fontWeight: 500, fontSize: 18 }}
                      primary="Total Amount"
                    />
                    <ListItemSecondaryAction
                      style={{ fontWeight: 500, fontSize: 18 }}
                    >
                      <p>₹27,990</p>
                    </ListItemSecondaryAction>
                  </ListItem>
                </Box>
                <ListItem>
                  <ListItemText
                    style={{ fontWeight: "500", color: "green", fontSize: 14 }}
                    disableTypography
                    primary="You will save ₹5,000 on this order"
                  />
                </ListItem>
              </Box>
            </List>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        width={350}
        ml="auto"
        mr="auto"
        pb={3}
        pt={3}
      >
        <VerifiedUserTwoTone />
        <p
          style={{
            color: "var(--gray)",
            fontWeight: 500,
            fontSize: 14,
            marginLeft: 20,
          }}
        >
          Safe and Secure Payments.Easy returns.100% Authentic products.
        </p>
      </Box>
      {matches && (
        <div
          style={{
            position: "sticky",
            bottom: 0,
            zIndex: 1,
          }}
        >
          <List
            disablePadding
            style={{
              background: "white",
            }}
          >
            <ListItem>
              <ListItemText
                primary="12,999"
                secondary="view price details"
                classes={{
                  secondary: classes.secondary,
                  primary: classes.primary,
                }}
              />
              <ListItemSecondaryAction>
                <Button
                  style={{
                    background: "#FB641B",
                    color: "white",
                    fontWeight: 600,
                    fontSize: 13,
                    textTransform: "capitalize",
                    width: 150,
                  }}
                >
                  place order
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </div>
      )}
    </div>
  );
};

export default CartPage;
