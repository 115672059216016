import { Route, Switch, useHistory } from "react-router-dom";
import HomePage from "../Pages/HomePage";
import SamsungHead from "../samsung/samsungHead";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import AllNavCategories, {
  ProductNav,
} from "../AllNavCategories/AllNavCategories";
import ProductsPage from "../Pages/ProductsPage";
import ProductPage from "../Pages/ProductPage";
import SortInMobilePage from "../SortInMobilePage";
import RatRev from "../RatRev";
import CartPage from "../Pages/CartPage";
import HeavyProductCard from "../Products/HeavyProductCard";
const theme = createMuiTheme();
const Routes = () => {
  const history = useHistory();
  return (
    <Switch>
      <Route exact path="/">
        <ThemeProvider theme={theme}>
          <HomePage />
        </ThemeProvider>
      </Route>
      <Route exact path="/s">
        <SamsungHead />
      </Route>
      <Route exact path="/allNavCategories">
        <AllNavCategories />
      </Route>
      <Route exact path="/products/:name/:categoryId">
        <ProductsPage />
      </Route>
      <Route exact path="/product/:productId">
        <ProductPage />
      </Route>
      <Route exact path="/qna">
        <RatRev />
      </Route>
      <Route exact path="/cart">
        <CartPage />
      </Route>
      <Route exact path="/sortInMobile">
        <ProductNav history={history} />
        <SortInMobilePage />
      </Route>
      <Route exact path="/h">
        <HeavyProductCard />
      </Route>
    </Switch>
  );
};

export default Routes;
