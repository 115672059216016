import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  qnaHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 74,
    borderBottom: "1px solid lightgray",
    //   padding: "0 20px",
  },
  details: {
    fontSize: 14,
    padding: 20,
    borderBottom: "1px solid lightgray",
  },
});
const Speciations = () => {
  const classes = useStyles();

  return (
    <div className="grayBorder">
      <div className={classes.qnaHeader}>
        <h1 style={{ marginLeft: 15, fontSize: 24, fontWeight: 500 }}>
          Specifications
        </h1>
      </div>
      <DetailsItem title="General" details="" />
      <DetailsItem title="Display Features" details="" />
      <DetailsItem title="Os & Processor Features" details="" />
      <div className={classes.qnaHeader}>
        <h1
          style={{
            marginLeft: 15,
            fontSize: 16,
            color: "var(--blue)",
            fontWeight: 500,
          }}
        >
          Read more
        </h1>
      </div>
    </div>
  );
};

const DetailsItem = ({ title, details }) => {
  const classes = useStyles();
  return (
    <div className={classes.details}>
      <p style={{ fontSize: 18, fontWeight: 500 }}>{title}</p>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          padding: "10px 0",
        }}
      >
        <p style={{ flex: 0.2, color: "var(--gray)" }}>In The Box</p>
        <p style={{ flex: 0.8 }}>
          Handset, Earphone (XE710), USB Cable, USB Power Adaptor, SIM Ejector
          Pin, Protective Case, Protective Film (Applied), Documentation
        </p>
      </div>
    </div>
  );
};

export default Speciations;
