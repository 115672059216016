import { useMediaQuery, useTheme } from "@material-ui/core";
import {
  Favorite,
  FiberManualRecordOutlined,
  FiberManualRecordRounded,
  Star,
} from "@material-ui/icons";
// import Skeleton from "@material-ui/lab/Skeleton";
import { useState, useEffect } from "react";
import "./Card.css";
import { Truncate } from "../../Pages/CartPage";
import { generateImageUrl } from "../../config";

const Card = ({
  offerPrice,
  image,
  name,
  rating,
  price,
  brand,
  actualPrice,
  isLoading,
  discount,
}) => {
  const [index, setIndex] = useState(0);
  const [show, setShow] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  // const image = [multicar1, multicar2, multicar5, multicar7];
  useEffect(() => {
    let mount = true;
    if (mount) {
      show &&
        setTimeout(() => {
          if (index < image?.length - 1 && mount) {
            setIndex(index + 1);
          } else {
            mount && setIndex(0);
          }
        }, 1000);
    } else {
      return null;
    }
    return () => (mount = false);
  }, [show, index, image?.length]);

  return (
    <div className="card" style={{ margin: matches && 10 }}>
      <div className="card__heart">
        <Favorite />
      </div>
      <div style={{ position: "absolute", top: 5 }}>
        {Array(image?.length)
          .fill()
          .map((_, i) => {
            if (i === index) {
              return (
                show && <FiberManualRecordRounded className="dots" key={i} />
              );
            } else {
              return (
                show && <FiberManualRecordOutlined className="dots" key={i} />
              );
            }
          })}
      </div>

      <div className="card__image">
        <img
          onMouseOver={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
          src={generateImageUrl(image[index].img)}
          // src={image}
          alt="images"
          loading="lazy"
        />
      </div>
      <div className="productDet">
        <div className="card__details">
          <p className="title">{brand}</p>
          <p>{Truncate(name, 20)}</p>
          <span className="span1">₹{offerPrice || price}</span>
          {actualPrice && <span className="span2">₹{actualPrice}</span>}
          {discount && <span className="span3">{discount}%</span>}
          <div
            className="flexStyle"
            style={{
              background: "#26A541",
              color: "white",
              fontSize: 13,
              padding: "2px 4px",
              borderRadius: 4,
            }}
          >
            <span>{rating}</span>
            <Star style={{ fontSize: 13 }} />
          </div>
        </div>
        {/* <div className="card__size">
          <p>
            size <span>6,7,8,9</span>
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default Card;
