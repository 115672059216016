import { makeStyles } from "@material-ui/core";
import {
  CheckCircle,
  ExpandMore,
  Search,
  ThumbDown,
  ThumbUp,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  qnaHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 78,
    borderBottom: "1px solid lightgray",
    //   padding: "0 20px",
  },
  searchIcon: {
    borderLeft: "1px solid lightgray",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 70,
  },
  qnas: {
    padding: 15,
    fontSize: 14,
    borderBottom: "1px solid lightgray",
  },
  thumbs: {
    display: "flex",
    alignItems: "center",
    color: "#C2C2C2",
    justifyContent: "space-between",
  },
}));
const Qna = ({ matches }) => {
  const classes = useStyles();
  return (
    <div
      style={{
        background: "white",
      }}
      className={matches ? "grayBorderTop" : "grayBorder"}
    >
      <div className={classes.qnaHeader}>
        <h1 style={{ marginLeft: 15, fontSize: "clamp(1rem,2vw,1.8rem)" }}>
          Questions and Answers
        </h1>
        <div className={classes.searchIcon}>
          <Search />
        </div>
      </div>
      <QuseAnss />
      <QuseAnss />
      <QuseAnss />
    </div>
  );
};

export default Qna;

const QuseAnss = () => {
  const classes = useStyles();

  return (
    <div className={classes.qnas}>
      <p style={{ fontWeight: 500, padding: "5px 0" }}>
        Q:&nbsp;how we share our dslr photos to our mobile
      </p>
      <p style={{ padding: "5px 0" }}>
        A:&nbsp;there is a app of canan in play store .. download it and then
        from the wifi feature in camera you will be able to share your pictures
        .
      </p>
      <div className={classes.thumbs}>
        <div style={{ flex: 0.85 }}>
          <p style={{ fontWeight: 500, padding: "5px 0" }}>Raja Alam</p>
          <div className="flexStyle">
            <CheckCircle style={{ fontSize: 17 }} />
            <p>Certified Buyer</p>
          </div>
        </div>
        <div
          style={{
            flex: 0.15,
            justifyContent: "space-around",
            display: "flex",
          }}
        >
          <ThumbUp /> <span>1025</span>
          <ThumbDown /> <span>246</span>
          <ExpandMore />
        </div>
      </div>
      <p style={{ fontWeight: 500, color: "var(--blue)", padding: "5px 0" }}>
        Read other answers
      </p>
    </div>
  );
};
