import { Grid } from "@material-ui/core";
import chikki from "../../assets/chikki.jpg";
const SmallDeviceCard = () => {
  return (
    <div
      style={{
        background: "linear-gradient(to bottom, #24FE41, #FDFC47)",
      }}
    >
      <div className="card_header flexStyle">
        <h1>Top Offers</h1>
        <button>view all</button>
      </div>
      <div
        style={{
          background: "white",
          margin: 15,
          boxShadow: " 0 1px 6px 0 rgb(32 33 36 / 28%)",
        }}
      >
        <Grid container>
          <Card name="Jumka" dis="Under&nbsp;₹50,000" img={chikki} />
          <Card
            name="Headset"
            dis="Min. 70% off"
            img="https://antesports.com/wp-content/uploads/2021/03/H520W-0-Green.jpg"
          />
          <Card
            name="Camera"
            dis="Under ₹ 7071"
            img="https://i.pinimg.com/originals/bc/aa/d0/bcaad00d4bf16fd94bc45665ddad18e9.jpg"
          />
          <Card
            name="Bedsheet"
            dis="Min. 70% off"
            img="https://www.clipartkey.com/mpngs/m/167-1678070_bed-sheet-png.png"
          />
        </Grid>
      </div>
    </div>
  );
};

const Card = ({ img, name, dis }) => {
  return (
    <Grid
      item
      xs={6}
      style={{
        textAlign: "center",
        border: "1px solid lightgray",
        padding: "20px 30px",
        height: "clamp(9rem,35vh,12rem)",
      }}
    >
      <img
        width="100%"
        style={{ objectFit: "contain", height: "70%" }}
        src={img}
        alt=""
      />
      <p style={{ fontSize: 14 }}>{name}</p>
      <p
        style={{ color: "green", fontWeight: 500, fontSize: 14, width: "100%" }}
      >
        {dis}
      </p>
    </Grid>
  );
};

export default SmallDeviceCard;
