import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useMutation, useQueryClient } from "react-query";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { axiosIntance } from "../config";
import { useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  nested: {
    padding: "0px",
    paddingLeft: theme.spacing(2),
  },
  label: {
    fontSize: 13,
    fontWeight: 500,
  },
}));

const NestedList = ({ item, categoryId, name }) => {
  const [brands, setBrands] = useState([]);
  const d = useLocation().search;
  const query = useMemo(() => new URLSearchParams(d), [d]);
  useEffect(() => {
    let brands = [];
    for (const [name, value] of query) {
      if (name.includes("brand") && value) brands.push(value);
      setBrands(brands);
      // a.push(value);
      // console.log(value.replace(/ /g,'').toLowerCase());
    }
    // console.log(brands);
  }, [query]);
  // console.log(brands);
  const queryClient = useQueryClient();
  const chiki = queryClient.getQueryData(["filters", categoryId]);
  // console.log(chiki);
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const mutateRatingFilters = useMutation(
    async (dat) => {
      const res = await axiosIntance.put(
        `/category/updateFils?categoryId=${categoryId}`,
        { rating: dat }
      );
      // console.log(dat);
      return res.data;
    },
    {
      onMutate: (newDatssa) => {
        queryClient.setQueryData(
          ["filters", categoryId],
          (current) => ({ ...current, rating: newDatssa })
          // console.log(newDatssa)
          // (current) => console.log(current.rating)
          // (current) => console.log(current.brands)
          // (current) => console.log(current)
        );
      },
    }
  );
  const handleChangeRadio = (event) => {
    mutateRatingFilters.mutateAsync(event.target.value);
  };

  const mutateBrandFilters = useMutation(
    async (dat) => {
      const res = await axiosIntance.put(
        `/category/updateFils?categoryId=${categoryId}`,
        { brands: dat }
      );
      return res.data;
    },
    {
      onMutate: (newData) => {
        queryClient.setQueryData(["filters", categoryId], (current) => ({
          ...current,
          brands: newData,
        }));
        // console.log(typeof newData);
      },
    }
  );
  const handleChange = (e, value) => {
    const currentIndex = chiki?.brands?.indexOf(value);
    const newChecked = [...chiki?.brands];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    // console.log(newChecked);
    mutateBrandFilters.mutateAsync(newChecked);
  };
  // if (item.includes("★")) return `rating=${item.match(/\d+/)[0]}&`;
  // else if (item.includes("%"))
  //   return `discount=${item.match(/\d+/)[0]}&`;
  // useEffect(() => {
  //   const url =
  //     checked.length !== 0
  //       ? checked.map((item, i) => {
  //           const identity = item.includes("★") ? "rating" : "brand";
  //           const value = item.includes("★") ? item.match(/\d+/)[0] : item;
  //           return `${identity}${i}=${value}&`;
  //         })
  //       : null;
  //   checked.length !== 0
  //     ? history.push(`/products/${name}/${categoryId}?${url.join("")}`)
  //     : history.push(`/products/${name}/${categoryId}`);
  //   // console.log("fired");
  // }, [checked, history]);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <List
        component="nav"
        style={{ borderBottom: "1px solid lightgray" }}
        disablePadding
        //   disableRipple
      >
        <ListItem
          button
          onClick={handleClick}
          style={{
            padding: 13,
          }}
        >
          <ListItemText
            disableTypography
            primary={item.title}
            style={{
              fontSize: 13,
              fontWeight: 500,
              textTransform: "uppercase",
            }}
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {item.title === "brand" && (
          <Collapse in={open} timeout={500} unmountOnExit>
            <List component="div" disablePadding>
              {item.child?.map((sub, i) => (
                <ListItem key={i} button className={classes.nested}>
                  <Checkbox
                    size="small"
                    disableRipple
                    // TODO:checked should match with url
                    checked={chiki?.brands?.indexOf(sub) === -1 ? false : true}
                    onChange={(e) => handleChange(e, sub)}
                  />
                  <ListItemText
                    disableTypography
                    style={{ fontSize: 13, fontWeight: 500 }}
                    primary={sub}
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
        )}
        {item.title === "customer ratings" && (
          <Collapse in={open} timeout={500} unmountOnExit>
            <List component="div" disablePadding>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={chiki?.rating}
                  onChange={handleChangeRadio}
                >
                  {item.child?.map((item, i) => (
                    <ListItem
                      key={i}
                      button
                      style={{ padding: 0, paddingLeft: 25 }}
                    >
                      <FormControlLabel
                        value={item}
                        control={<Radio size="small" />}
                        label={item}
                        classes={{ label: classes.label }}
                      />
                    </ListItem>
                  ))}
                </RadioGroup>
              </FormControl>
            </List>
          </Collapse>
        )}
      </List>
    </>
  );
};

export default React.memo(NestedList);
