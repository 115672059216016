import { ButtonBase } from "@material-ui/core";
const HeaderBottomItemVer2 = ({ item }) => {
  return (
    <ButtonBase>
      <div className="headerBottom__item flexStyle">
        <div className="header__bottomItemImage">
          <img src={item.image} alt="" />
        </div>
        <p>{item.title}</p>
      </div>
    </ButtonBase>
  );
};

export default HeaderBottomItemVer2;
