import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.css";
import { dataa } from "../data";
import { generateImageUrl } from "../config";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";

const PreviousBtn = (props) => {
  // console.log(props);
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowBackIos style={{ color: "blue", fontSize: "30px" }} />
    </div>
  );
};
const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowForwardIos style={{ color: "blue", fontSize: "30px" }} />
    </div>
  );
};

const settings = {
  autoplay: true,
  autoplaySpeed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  speed: 700,
  nextArrow: <NextBtn />,
  prevArrow: <PreviousBtn />,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        arrows: false,
        swipeToSlide: true,
        fade: true,
        dots: true,
        customPaging: function (i) {
          return <div className="dot"></div>;
        },
        dotsClass: "slick-dots slick-thumb",
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        swipeToSlide: true,
        fade: true,
        dots: true,
        customPaging: function (i) {
          return <div className="dot"></div>;
        },
        dotsClass: "slick-dots slick-thumb",
      },
    },
  ],
};

const Carousel = ({ data, normal }) => {
  const toMap = data ? data : dataa;
  return (
    <div style={{ margin: 10 }} className="homePage__carousel">
      <Slider {...settings}>
        {toMap.map((item) => (
          <div key={item}>
            <img
              src={item.img ? generateImageUrl(item.img) : item}
              alt=""
              style={{
                width: "100%",
                height: "clamp(7rem,40vh,18rem)",
                objectFit: data ? "contain" : "cover",
              }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
