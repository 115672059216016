import { ProductNav } from '../AllNavCategories/AllNavCategories';
import Header from '../Components/Header/Header';
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  // ListItemIcon,
  ListItemText,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  // ExpandMore,
  FlashOn,
  LocalOffer,
  NavigateNext,
  ShoppingBasket,
  Star,
} from '@material-ui/icons';
import Qna from '../Qna';
import RatRev from '../RatRev';
import supercoin from '../assets/supercoin.png';
import Speciations from '../Speciations';
// import MultiItemCarousel from "../carousel/MultiItemCrousel";
import Footer from '../Components/Footer/Footer';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { useState } from 'react';
import React from 'react';
import Carousel from '../carousel/Carousel';
import { axiosIntance, generateImageUrl } from '../config';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});
const ProductPage = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div>
      {matches ? <ProductNav title='chikki' /> : <Header />}
      {/* <HeaderBottomNav /> */}
      <ProductDetails />
    </div>
  );
};

const ProductDetails = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { productId } = useParams();
  // const productRef = useRef();
  // useEffect(() => {
  //   console.log(productRef.current.scrollHeight);
  // }, []);
  // let position = productRef.current?.scrollHeight > 1290 ? "static" : "sticky";
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const { data, isLoading } = useQuery(
    ['singleProduct', productId],
    async () => {
      return await axiosIntance.get(`/product/getproductById/${productId}`);
    }
  );
  //! instead of just using string as querykey, use array of querykey,id["",id] beacause if you use only string the previous value shows for time before getting the new value.
  const [index, setIndex] = useState(0);
  console.log(data?.data);
  const ProductMini = ({ img, i }) => {
    return (
      <Box
        width='70px'
        height='70px'
        display='flex'
        alignItems='center'
        justifyContent='center'
        padding={1}
        onMouseOver={() => setIndex(i)}
        style={{ border: i === index && '2px solid var(--blue)' }}
      >
        <img
          src={generateImageUrl(img)}
          alt=''
          width='100%'
          height='100%'
          style={{ objectFit: 'contain', cursor: 'pointer' }}
        />
      </Box>
    );
  };
  return (
    <>
      {isLoading ? (
        <CircularProgress thickness={5} style={{ width: 200, height: 200 }} />
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: `${matches ? 'column' : 'row'}`,
            }}
          >
            {matches ? (
              <Carousel data={data?.data.images} normal />
            ) : (
              <div
                style={{
                  flex: matches ? 1 : 0.395,
                  position: 'sticky',
                  top: 70,
                  height: '90vh',
                }}
              >
                <Box
                // justifyContent="center"
                >
                  <Box height='100%' display='flex' alignItems='center'>
                    <Box flex='0.15' ml={2} height='100%'>
                      {data?.data.images.map((item, i) => (
                        <ProductMini img={item.img} key={item._id} i={i} />
                      ))}
                    </Box>
                    <Box
                      flex='0.85'
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      py={1}
                      minHeight='70vh'
                    >
                      <img
                        src={generateImageUrl(data?.data.images[index].img)}
                        alt=''
                      />
                    </Box>
                  </Box>
                  {/* //?Buttons */}
                  {!matches && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        // marginTop: 15,
                        marginRight: 10,
                        // marginBottom: 100,
                      }}
                    >
                      <Button
                        style={{
                          background: '#FF9F00',
                          color: 'white',
                          fontWeight: 600,
                          padding: '15px 0px',
                          width: 217,
                        }}
                        startIcon={<ShoppingBasket />}
                      >
                        Add to cart
                      </Button>
                      <Button
                        style={{
                          background: '#FB641B',
                          color: 'white',
                          width: 217,
                          fontWeight: 600,
                          padding: '15px 0px',
                          marginLeft: '10px',
                        }}
                        startIcon={<FlashOn />}
                      >
                        Proceed to buy
                      </Button>
                    </div>
                  )}
                </Box>
              </div>
            )}
            {/*//?details starts */}
            <div style={{ flex: matches ? 1 : 0.605 }}>
              {/* //?Breadcrumbs */}
              {!matches && (
                <div style={{ fontSize: 10, padding: '5px 0' }}>
                  <Breadcrumbs
                    separator={<NavigateNext fontSize='small' />}
                    aria-label='breadcrumb'
                  >
                    <Typography
                      style={{ fontSize: 12, color: 'gray' }}
                      color='textPrimary'
                    >
                      Breadcrumb
                    </Typography>
                    <Typography
                      style={{ fontSize: 12, color: 'gray' }}
                      color='textPrimary'
                    >
                      Breadcrumb
                    </Typography>
                    <Typography
                      style={{ fontSize: 12, color: 'gray' }}
                      color='textPrimary'
                    >
                      Breadcrumb
                    </Typography>
                  </Breadcrumbs>
                </div>
              )}
              <div style={{ margin: '0 20px' }}>
                <div>
                  <Typography variant='subtitle1' style={{ padding: '5px 0' }}>
                    {data?.data.name}
                  </Typography>
                  <div className='flexStyle' style={{ padding: '5px 0' }}>
                    <div
                      className='flexStyle'
                      style={{
                        background: '#26A541',
                        color: 'white',
                        fontSize: 13,
                        padding: '2px 4px',
                        borderRadius: 4,
                      }}
                    >
                      <span>{data?.data.rating}</span>
                      <Star style={{ fontSize: 13 }} />
                    </div>
                    <p
                      style={{
                        fontWeight: 600,
                        color: 'gray',
                        fontSize: 15,
                        marginLeft: 10,
                      }}
                    >
                      20,781 Ratings & 3,294 Reviews
                    </p>
                    <img
                      src='https://static-assets-web.flixcart.com/www/linchpin/fk-cp-zion/img/fa_62673a.png'
                      alt=''
                      width='70'
                      style={{
                        marginLeft: 10,
                      }}
                    />
                  </div>
                  <div style={{ padding: '5px 0' }}>
                    <p>Special price</p>
                    <div style={{ padding: '5px 0' }}>
                      <span style={{ fontSize: 28, fontWeight: 600 }}>
                        {data?.data.offerPrice
                          ? '₹' + data?.data.offerPrice
                          : '₹' + data?.data.cost}
                      </span>
                      {data?.data.actualPrice && (
                        <span
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            textDecoration: 'line-through',
                            color: 'gray',
                            marginLeft: 10,
                          }}
                        >
                          {'₹' + data?.data.actualPrice}
                        </span>
                      )}
                      {data?.data.offerPrice && (
                        <span
                          style={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: 'green',
                            marginLeft: 10,
                          }}
                        >
                          {data?.data.discount + '%off'}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <p style={{ fontSize: 16, fontWeight: 500 }}>
                    Available offers
                  </p>
                  <div style={{ display: 'flex', padding: 5 }}>
                    <LocalOffer style={{ color: '#14BD49', fontSize: 18 }} />
                    <p
                      style={{
                        fontSize: 14,
                        marginLeft: 7,
                        marginTop: -1.5,
                      }}
                    >
                      <span style={{ fontWeight: 500 }}>Combo Offer</span> Buy 2
                      items save 5%;Buy 3 or more save 10%See all products{' '}
                      <span style={{ color: 'var(--blue)', fontWeight: 500 }}>
                        T&C
                      </span>
                    </p>
                  </div>
                  <div style={{ display: 'flex', padding: 5 }}>
                    <LocalOffer style={{ color: '#14BD49', fontSize: 18 }} />
                    <p
                      style={{
                        fontSize: 14,
                        marginLeft: 7,
                        marginTop: -1.5,
                      }}
                    >
                      <span style={{ fontWeight: 500 }}>Combo Offer</span> Buy 2
                      items save 5%;Buy 3 or more save 10%See all products{' '}
                      <span style={{ color: 'var(--blue)', fontWeight: 500 }}>
                        T&C
                      </span>
                    </p>
                  </div>
                  <div style={{ display: 'flex', padding: 5 }}>
                    <LocalOffer style={{ color: '#14BD49', fontSize: 18 }} />
                    <p
                      style={{
                        fontSize: 14,
                        marginLeft: 7,
                        marginTop: -1.5,
                      }}
                    >
                      <span style={{ fontWeight: 500 }}>Combo Offer</span> Buy 2
                      items save 5%;Buy 3 or more save 10%See all products{' '}
                      <span style={{ color: 'var(--blue)', fontWeight: 500 }}>
                        T&C
                      </span>
                    </p>
                  </div>
                  <div style={{ display: 'flex', padding: 5 }}>
                    <LocalOffer style={{ color: '#14BD49', fontSize: 18 }} />
                    <p
                      style={{
                        fontSize: 14,
                        marginLeft: 7,
                        marginTop: -1.5,
                      }}
                    >
                      <span style={{ fontWeight: 500 }}>Combo Offer</span> Buy 2
                      items save 5%;Buy 3 or more save 10%See all products{' '}
                      <span style={{ color: 'var(--blue)', fontWeight: 500 }}>
                        T&C
                      </span>
                    </p>
                  </div>
                  <p
                    style={{
                      color: 'var(--blue)',
                      fontWeight: 500,
                      fontSize: 14,
                      padding: 5,
                    }}
                  >
                    View 6 more offers
                  </p>
                </div>
              </div>
              <Box margin='5px 20px'>
                <Box onClick={handleClickOpen}>
                  <img
                    src={supercoin}
                    alt=''
                    width='50%'
                    // height="200"
                    style={{ objectFit: 'contain', cursor: 'pointer' }}
                  />
                </Box>
                {data?.data.description && (
                  <Box display='flex'>
                    <p
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: 'var(--gray)',
                        marginRight: 40,
                      }}
                    >
                      Description
                    </p>
                    <p
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {data?.data.description}
                    </p>
                  </Box>
                )}
              </Box>
              {/* //? Dialogue*/}
              <div>
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  maxWidth='xs'
                  onClose={handleClose}
                  aria-labelledby='alert-dialog-slide-title'
                  aria-describedby='alert-dialog-slide-description'
                >
                  <DialogTitle id='alert-dialog-slide-title'>
                    Flipkart SuperCoins
                  </DialogTitle>
                  <Divider />
                  <DialogContent style={{ fontSize: 14 }}>
                    <Box display='flex' alignItems='center' my={1.5}>
                      <img
                        style={{ marginRight: 10 }}
                        src='https://rukminim1.flixcart.com/lockin/16/16/images/super_coin_24x24.png?q=90'
                        alt=''
                      />
                      <p>Earn SuperCoins on Flipkart orders</p>
                    </Box>
                    <Box ml={3.3} mt={1.5} mb={2.5}>
                      <p style={{ fontWeight: 500, margin: '5px 0' }}>
                        How do I earn SuperCoins?
                      </p>
                      <p style={{ fontWeight: 500, margin: '5px 0' }}>
                        Shop for ₹100 = Earn 2 SuperCoins
                      </p>
                      <p style={{ fontSize: 12, margin: '5px 0' }}>
                        "Max 50 SuperCoins per order. SuperCoins are credited
                        after the return period is over for all the products in
                        the order"
                      </p>
                    </Box>
                    <Box my={2}>
                      <p>
                        Exchange SuperCoins to get additional offers on
                        shopping, food, travel and more
                      </p>
                    </Box>
                  </DialogContent>
                </Dialog>
              </div>
              {/* //? Dialogue*/}
              <div
                style={{
                  background: matches && 'var(--backgroundGray)',
                  paddingTop: 15,
                }}
              >
                <div style={{ marginBottom: 20 }}>
                  {!matches && <Speciations />}
                </div>
                <div
                  className={!matches ? 'grayBorder' : ''}
                  style={{ marginBottom: matches ? 15 : 20 }}
                >
                  <RatRev />
                </div>
                <Qna matches={matches} />
              </div>
            </div>
          </div>
          <div
            style={{ height: 15, background: 'var(--backgroundGray)' }}
          ></div>
          {matches && (
            <div
              style={{
                position: 'sticky',
                bottom: 0,
                zIndex: 1,
                // padding: "20px 0",
              }}
            >
              <List
                disablePadding
                style={{
                  display: 'flex',
                  boxShadow: '0 1px 6px 0 rgb(32 33 36 / 28%)',
                }}
              >
                <ListItem
                  button
                  style={{
                    background: 'white',
                    textTransform: 'uppercase',
                    fontWeight: 600,
                    fontSize: 16,
                    padding: 13,
                  }}
                >
                  <ListItemText
                    primary='add to cart'
                    disableTypography
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  />
                </ListItem>
                <ListItem
                  button
                  style={{
                    background: '#FB641B',
                    color: 'white',
                    textTransform: 'uppercase',
                    padding: 13,
                    fontWeight: 600,
                    fontSize: 16,
                  }}
                >
                  <ListItemText
                    primary='buy now'
                    disableTypography
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  />
                </ListItem>
              </List>
            </div>
          )}
          <div
            style={{ height: 15, background: 'var(--backgroundGray)' }}
          ></div>
          <div
            style={{
              background: 'var(--backgroundGray)',
            }}
          >
            {/* <MultiItemCarousel matches={matches} />
            <MultiItemCarousel matches={matches} />
            <MultiItemCarousel matches={matches} /> */}
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

// const HeaderBottomNav = () => {
//   const headerBottomNavItems = [
//     "Electronics",
//     "TVs&Appliances",
//     "Men",
//     "Women",
//     "Baby&Kids",
//     "Home&Furniture",
//     "Sports,Books&More",
//     "Flights",
//   ];
//   return (
//     <div>
//       <List
//         style={{ display: "flex", boxShadow: "rgba(0,0,0,0.16) 0 1px 1px 0" }}
//         disablePadding
//       >
//         {headerBottomNavItems.map((item, i) => (
//           <ListItem button key={i}>
//             <ListItemText
//               disableTypography
//               primary={item}
//               style={{ fontWeight: 500, fontSize: 15 }}
//             />
//             <ListItemIcon>
//               <ExpandMore style={{ color: "gray", fontSize: 18 }} />
//             </ListItemIcon>
//           </ListItem>
//         ))}
//       </List>
//     </div>
//   );
// };

export default ProductPage;
// ? marginTop,bottom won't work if alignItems is there
