import {
  AppBar,
  Box,
  Divider,
  Drawer,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Slide,
  Toolbar,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
  ListItemIcon,
} from '@material-ui/core';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import {
  ExpandMore,
  Search,
  Menu,
  ShoppingBasket,
  Add,
  Favorite,
  Chat,
} from '@material-ui/icons';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ProductNav } from '../../AllNavCategories/AllNavCategories';
import { useDebounce } from '../../hooks/debounce';
import './Header.css';
import { axiosIntance } from '../../config';
import TransitionsModal from '../../Pages/Register/Modal';
const MenuItem = ({ text, index }) => {
  return (
    <ListItem button key={text}>
      {index % 2 === 0 ? (
        <Favorite style={{ fontSize: 20, marginRight: 10, color: 'gray' }} />
      ) : (
        <Chat style={{ fontSize: 20, marginRight: 10, color: 'gray' }} />
      )}
      <ListItemText
        primary={text}
        disableTypography
        style={{ fontWeight: 500, fontSize: 14 }}
      />
    </ListItem>
  );
};
const drawer = (
  <div>
    {/* <div className={classes.toolbar} /> */}
    <ProductNav />
    <List disablePadding>
      {['SuperCion Zone', 'Flipkart Plus Zone'].map((text, index) => (
        <MenuItem index={index} text={text} key={index} />
      ))}
    </List>
    <Divider />
    <List disablePadding>
      {['All categories', 'More on Flipkart'].map((text, index) => (
        <MenuItem index={index} text={text} key={index} />
      ))}
    </List>
    <Divider />
    <List disablePadding>
      {['Choose Language'].map((text, index) => (
        <MenuItem index={index} text={text} key={index} />
      ))}
    </List>
    <Divider />
    <List disablePadding>
      {['Offer Zone', 'Sell on Flipkart'].map((text, index) => (
        <MenuItem index={index} text={text} key={index} />
      ))}
    </List>
    <Divider />
    <List disablePadding>
      {[
        'My Orders',
        'My Coupons',
        'My Cart',
        'My Wishlist',
        'My Accpount',
        'My Notifications',
        'My Chats',
      ].map((text, index) => (
        <MenuItem index={index} text={text} key={index} />
      ))}
    </List>
    <Divider />
    <List disablePadding>
      {['Notification preferences', 'Help Center', 'Legal'].map(
        (text, index) => (
          <ListItem button key={text}>
            <ListItemText
              primary={text}
              disableTypography
              style={{ fontWeight: 500, fontSize: 14 }}
            />
          </ListItem>
        )
      )}
    </List>
  </div>
);

const Header = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const drawerWidth = 270;
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const handleOpen = () => {
    setOpen(true);
  };
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 56,
  });
  const useStyles = makeStyles({
    appBar: {
      background: '#2874F0',
      position: matches ? 'sticky' : 'static',
      padding: !matches && '16px 0',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },

    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  });
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  //! if you wrap appBar in a div position sticky won't work
  return (
    <>
      <AppBar className={classes.appBar} elevation={0}>
        <Toolbar className='flexStyle' style={{ minHeight: 56 }}>
          <Grid
            container
            justify={!matches ? 'center' : 'space-between'}
            alignItems='center'
          >
            <Grid item lg={1} md={false} sm={false} xs={false} xl={2} />
            {matches ? (
              <Logo />
            ) : (
              <Grid container justify='center'>
                <Grid item xs={7} sm={6} className='flexStyle'>
                  <Menu
                    style={{ marginRight: 10 }}
                    onClick={handleDrawerToggle}
                  />
                  <Logo />
                </Grid>
                <Grid
                  item
                  xs={5}
                  sm={3}
                  style={{ justifyContent: 'space-between' }}
                  className='flexStyle'
                >
                  <Add />
                  <ShoppingBasket />
                  <p>Login</p>
                </Grid>
              </Grid>
            )}
            <In open={open} setOpen={setOpen} />
            <Hidden smDown>
              <div className='loginButton' onClick={handleOpen}>
                <button style={{ cursor: 'pointer' }}>Login</button>
              </div>
              <div className='flexStyle More'>
                <p style={{ fontSize: 16, fontWeight: 500 }}>More</p>
                <ExpandMore style={{ fontSize: 17, marginTop: 2 }} />
              </div>
              <div
                className='flexStyle Cart'
                onClick={() => history.push('/cart')}
              >
                <ShoppingBasket />
                <p style={{ fontSize: 16, fontWeight: 500, marginLeft: 5 }}>
                  Cart
                </p>
              </div>
            </Hidden>
            <Grid item lg={1} md={false} sm={false} xs={false} xl={2} />
          </Grid>
        </Toolbar>
      </AppBar>
      {!matches && (
        <Slide in={trigger} direction='down' style={{ position: 'fixed' }}>
          <AppBar style={{ background: 'var(--blue)' }}>
            <Toolbar
              style={{ justifyContent: 'space-around', display: 'flex' }}
            >
              <Menu />
              <Box width='80%'>
                <In />
              </Box>
              <ShoppingBasket />
            </Toolbar>
          </AppBar>
        </Slide>
      )}
      <nav className={classes.drawer} aria-label='mailbox folders'>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation='css'>
          <Drawer
            variant='temporary'
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </>
  );
};

const In = ({ setOpen, open }) => {
  const [search, setSearch] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [data, setData] = useState([]);
  // const history = useHistory();
  const prepareSearchQuery = (query) => {
    const url = `/category/search?q=${query}`;
    return encodeURI(url);
  };
  const searchProduct = async () => {
    if (!search || search.trim() === '') return;
    const URL = prepareSearchQuery(search);
    // history.push(`/search?q=${search}`);
    try {
      const response = await axiosIntance.get(URL);
      setData(response.data);
      console.log(response.data);
    } catch (error) {
      return console.log(error);
    }
  };
  useDebounce(search, 500, searchProduct);
  const ar = [
    { name: 'mobiles' },
    { name: 'shoes' },
    { name: 't shirts' },
    { name: 'laptops' },
    { name: 'tv' },
    { name: 'watches' },
    { name: 'sarees' },
  ];
  const searches = search === '' ? ar : data;
  return (
    <div className='header__search flexStyle' style={{ position: 'relative' }}>
      <ClickAwayListener onClickAway={() => setExpanded(false)}>
        <form className=' header__search' onSubmit={searchProduct}>
          <Box display='flex' alignItems='center'>
            <input
              type='text'
              placeholder='Search for products,brands and more'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onFocus={() => setExpanded(true)}
            />
            <Search />
          </Box>
          <Divider />
          {expanded && data && (
            <Box
              style={{ background: 'white' }}
              width='100%'
              // height="100%"
              position='absolute'
              zIndex={10}
            >
              <List disablePadding>
                {searches.map((item) => (
                  <ListItem
                    to={`/products/${item.name}/${item._id}`}
                    component={Link}
                    button
                    key={item._id}
                  >
                    <ListItemIcon>
                      <Search />
                    </ListItemIcon>
                    <ListItemText
                      // primary={item}
                      primary={item.name}
                      disableTypography
                      style={{
                        color: 'black',
                        fontWeight: 500,
                        fontSize: 13,
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          <button style={{ display: 'none' }} type='submit'></button>
        </form>
      </ClickAwayListener>
      <TransitionsModal open={open} handleClose={() => setOpen(false)} />
    </div>
  );
};

const Logo = () => {
  return (
    <Link to='/'>
      <div className='logo'>
        <img
          src='//img1a.flixcart.com/www/linchpin/fk-cp-zion/img/flipkart-plus_8d85f4.png'
          alt='flipkart logo'
          width='74'
        />
        <div className='flexStyle' style={{ marginTop: -10 }}>
          <span
            style={{
              fontSize: '11px',
              paddingRight: '2px',
              color: 'white',
              fontStyle: 'italic',
            }}
          >
            Explore
          </span>
          <span
            style={{
              color: '#F9E107',
              fontSize: '11px',
              fontStyle: 'italic',
            }}
          >
            Plus
          </span>
          <span>
            <img
              width='10'
              className='logo'
              src='//img1a.flixcart.com/www/linchpin/fk-cp-zion/img/plus_b13a8b.png'
              alt=''
            />
          </span>
        </div>
      </div>
    </Link>
  );
};

export default Header;
