import Header from '../Components/Header/Header';
import HeaderBottom from '../Components/Header/HeaderBottom';
import Carousel from '../carousel/Carousel';
import MultiItemCarousel from '../carousel/MultiItemCrousel';
import { Grid, Hidden } from '@material-ui/core';
import carside from '../assets/carside.jpg';
import Footer from '../Components/Footer/Footer';
import SmallDeviceCard from '../Components/Card/SmallDeviceCard';
import { useMultiItemCarousels } from '../hooks/useProducts';

const HomePage = () => {
  const { data } = useMultiItemCarousels();

  return (
    <div className='app'>
      <Header />
      <HeaderBottom />
      <Carousel />
      <Grid container justify='center'>
        <Hidden>
          <Grid item lg={10} md={9} sm={12} xs={12} xl={7}>
            <MultiItemCarousel data={data} />
          </Grid>
          <Grid item lg={2} md={3} sm={12} xs={12} xl={1}>
            <div
              style={{
                marginRight: 10,
                marginTop: 10,
                height: '368px',
                boxShadow: '0 2px 4px 0 rgb(0 0 0 / 8%)',
              }}
            >
              <img
                style={{ border: '4px solid white' }}
                src={carside}
                height='100%'
                alt=''
                width='100%'
              />
            </div>
          </Grid>
        </Hidden>
        <Hidden smUp>
          <SmallDeviceCard />
        </Hidden>
        {/* <Grid item xs={12}>
          <MultiItemCarousel slides={5} />
        </Grid>
        <Grid item xs={12}>
          <MultiItemCarousel slides={5} />
        </Grid>
        <Grid item xs={12}>
          <MultiItemCarousel slides={5} />
        </Grid> */}
      </Grid>
      <Footer />
    </div>
  );
};

export default HomePage;
