import { Grid, Box, Hidden, useMediaQuery, useTheme } from "@material-ui/core";
import { Favorite, Star } from "@material-ui/icons";
import { generateImageUrl } from "../config";
import { Truncate } from "../Pages/CartPage";
import { Link } from "react-router-dom";
const HeavyProductCard = ({ item }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  // console.log(item);
  return (
    <Box my={2.5} component={Link} to={`/product/${item._id}`}>
      <Grid container>
        <Grid item lg={3} xs={3}>
          <Box display="flex" flexDirection="column">
            <Hidden xsDown>
              <Box display="flex" justifyContent="flex-end" mr={4} mb={1}>
                <Favorite
                  style={{
                    fontSize: "20px",
                    color: "green",
                  }}
                />
              </Box>
            </Hidden>
            <img
              // src="https://rukminim1.flixcart.com/image/312/312/kndi4y80/television/v/x/f/65h71-65h71-iffalcon-original-imag22ghd99rxdmt.jpeg?q=70"
              src={generateImageUrl(item.images[0].img)}
              alt=""
              style={{ width: "88%", padding: 10 }}
            />
          </Box>
        </Grid>
        <Grid item lg={9} xs={8}>
          <Grid container direction={matches ? "column" : "row"}>
            <Grid item lg={8} xs={12}>
              <Box width={matches ? "100%" : "90%"}>
                <Box>
                  <p
                    style={{
                      fontWeight: 500,
                      fontSize: "clamp(0.7rem,2.5vw,1.1rem)",
                    }}
                  >
                    {Truncate(item.name, 88)}
                  </p>
                </Box>
                <Box className="flexStyle" padding="5px 0">
                  <div
                    className="flexStyle"
                    style={{
                      background: "#26A541",
                      color: "white",
                      fontSize: 13,
                      padding: "2px 4px",
                      borderRadius: 4,
                    }}
                  >
                    <span>{item.rating}</span>
                    <Star style={{ fontSize: 13 }} />
                  </div>
                  <p
                    style={{
                      fontWeight: 600,
                      color: "gray",
                      fontSize: 15,
                      marginLeft: 10,
                    }}
                  >
                    {matches ? "(20,781)" : "20,781 Ratings & 3,294 Reviews"}
                  </p>
                  <img
                    src="https://static-assets-web.flixcart.com/www/linchpin/fk-cp-zion/img/fa_62673a.png"
                    alt=""
                    width="70"
                    style={{
                      marginLeft: 15,
                      objectFit: "contain",
                    }}
                  />
                </Box>
                <Hidden xsDown>
                  <Box pt={2} ml={2}>
                    <ul style={{ fontSize: 14 }}>
                      <li>Netflix|Prime Video|Disney+Hotstar|Youtube</li>
                      <li>Operating System: Android</li>
                      <li>Ultra HD (4K) 3840 x 2160 Pixels</li>
                      <li>20 W Speaker Output</li>
                      <li>60 Hz Refresh Rate</li>
                      <li>3 x HDMI | 2 x USB</li>
                      <li>1 Year Domestic Warranty on Product</li>
                    </ul>
                  </Box>
                </Hidden>
              </Box>
            </Grid>
            <Grid item lg={4} xs={12}>
              <Box display="flex" alignItems="center">
                <p
                  style={{
                    fontSize: "clamp(0.7rem,2.5vw,1.8rem)",
                    fontWeight: 600,
                  }}
                >
                  ₹{item.offerPrice}
                </p>
                <Hidden xsDown>
                  <img
                    src="https://static-assets-web.flixcart.com/www/linchpin/fk-cp-zion/img/fa_62673a.png"
                    alt=""
                    width="70"
                    style={{
                      marginLeft: 15,
                      objectFit: "contain",
                    }}
                  />
                </Hidden>
              </Box>
              <Box display="flex">
                <p
                  style={{
                    fontSize: 14,
                    textDecoration: "line-through",
                    marginRight: 10,
                  }}
                >
                  ₹{item.actualPrice}
                </p>
                <p
                  style={{
                    fontSize: 14,
                  }}
                >
                  {item.discount}% off
                </p>
              </Box>
              <p style={{ fontSize: 14 }}>Bank Offer</p>
              <p style={{ fontSize: 12 }}>No Cost EMI</p>
            </Grid>
          </Grid>
        </Grid>
        <Hidden smUp>
          <Grid item xs={1}>
            <Box
              borderRadius="50%"
              boxShadow="0 1px 2px 0 rgb(0 0 0 / 26%)"
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="35px"
              width="35px"
            >
              <Favorite
                style={{
                  fontSize: "20px",
                  color: "green",
                }}
              />
            </Box>
          </Grid>
        </Hidden>
      </Grid>
    </Box>
  );
};

export default HeavyProductCard;
