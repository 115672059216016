import axios from 'axios';

const toServe =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:7071'
    : 'https://flipkart-clone-tu0g.onrender.com';
export const API = toServe;
// console.log(process.env.NODE_ENV);

export const generateImageUrl = (img) => {
  return `${toServe}/public/${img}`;
};

export const axiosIntance = axios.create({
  baseURL: toServe,
});
