import topoffers from "../../assets/topoffers.png";
import grocery from "../../assets/grocery.png";
import mobiles from "../../assets/mobiles.png";
import fashion from "../../assets/fashion.png";
import appliances from "../../assets/appliances.png";
import beauty from "../../assets/beauty.png";
import travel from "../../assets/travel.png";
import home from "../../assets/home.png";
import "./HeaderBottom.css";
import electronics from "../../assets/electronics.png";
import HeaderBottomItemVer2 from "./HeaderBottomItemVer2";
import { List } from "@material-ui/icons";
import { ButtonBase, Hidden, IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import React from "react";
const HeaderBottom = () => {
  return (
    <div className="headerBottom flexStyle">
      <Hidden smUp>
        <ButtonBase component={Link} to="/allNavCategories">
          <div
            className="flexStyle"
            style={{
              marginLeft: 10,
              flexDirection: "column",
            }}
          >
            <IconButton
              size="small"
              style={{
                background: "var(--blue)",
                color: "white",
              }}
            >
              <List style={{ fontSize: 30 }} />
            </IconButton>
            <p
              style={{
                fontSize: 13,
                color: "rgb(52,52,52)",
                fontWeight: 500,
                marginTop: 10,
              }}
            >
              All&nbsp;categories
            </p>
          </div>
        </ButtonBase>
      </Hidden>
      {navdata.map((item, i) => (
        <HeaderBottomItemVer2 item={item} key={i} />
      ))}
    </div>
  );
};

export default React.memo(HeaderBottom);

export const navdata = [
  { tippy: false, title: "TopOffers", image: topoffers },
  { tippy: false, title: "Grocery", image: grocery },
  { tippy: false, title: "Mobiles", image: mobiles },
  { tippy: true, title: "Fashion", image: fashion },
  { tippy: true, title: "Electronics", image: electronics },
  { tippy: true, title: "Home", image: home },
  { tippy: true, title: "Appliances", image: appliances },
  { tippy: false, title: "Travel", image: travel },
  { tippy: true, title: "Beauty,Toys&More", image: beauty },
];
