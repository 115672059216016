import {
  Badge,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import FullScreenDialog from "./Dialogue";
import { FilterList, Sort } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";
const FilterAndSrt = ({ matches }) => {
  const useStyles = makeStyles((theme) => ({
    liitem: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  }));
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();
  //! just by giving listItem justifyCon=center will not work,as ListItemText is given flex:1
  return (
    <List
      style={{ display: "flex", boxShadow: "0 2px 4px 0 rgb(0 0 0 / 8%)" }}
      component="li"
      disablePadding
    >
      <ListItem
        button
        className={classes.liitem}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={handleClickOpen}
      >
        <Sort />
        <ListItemText primary="Sort" style={{ flex: 0.3, marginLeft: 7 }} />
      </ListItem>
      <FullScreenDialog open={open} handleClose={handleClose} />
      <ListItem
        button
        component={matches && Link}
        to={matches && "/sortInMobile"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Badge badgeContent={7} color="error">
          <FilterList />
        </Badge>
        <ListItemText primary="Filter" style={{ flex: 0.3, marginLeft: 14 }} />
      </ListItem>
    </List>
  );
};
export default React.memo(FilterAndSrt);
