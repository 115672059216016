import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
const child = [
  "10% or more",
  "20% or more",
  "30% or more",
  "40% or more",
  "50% or more",
  "60% or more",
  "70% or more",
  "80% or more",
  "90% or more",
  "10% and below",
];
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    // height: 224,
  },
  tabs: {
    // borderRight: `1px solid ${theme.palette.divider}`,
    width: 170,
  },
  tab: {
    background: "#EDEFF2",
    fontWeight: 500,
    fontSize: 15,
    textTransform: "capitalize",
    "&.Mui-selected": {
      // outline: "none",
      color: "var(--blue)",
      background: "white",
    },
  },
  label: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
}));

export default function VerticalTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
        TabIndicatorProps={{ style: { display: "none" } }}
      >
        {Array(11)
          .fill("")
          .map((_, i) => (
            <Tab
              key={i}
              classes={{ wrapper: classes.label }}
              label="price"
              className={classes.tab}
            />
          ))}
      </Tabs>
      <TabPanel value={value} index={0}>
        <List component="li">
          {child.map((item, i) => (
            <ListItem key={i} button>
              <ListItemIcon>
                <Checkbox />
              </ListItemIcon>
              <ListItemText
                disableTypography
                style={{ fontSize: 13, fontWeight: 500 }}
                primary={item}
              />
            </ListItem>
          ))}
        </List>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <List component="li">
          {child.map((item, i) => (
            <ListItem key={i} button>
              <ListItemIcon>
                <Checkbox />
              </ListItemIcon>
              <ListItemText
                disableTypography
                style={{ fontSize: 13, fontWeight: 500 }}
                primary={item}
              />
            </ListItem>
          ))}
        </List>
      </TabPanel>
      <TabPanel value={value} index={9}>
        <List component="li">
          {child.map((item, i) => (
            <ListItem key={i} button>
              <ListItemIcon>
                <Checkbox />
              </ListItemIcon>
              <ListItemText
                disableTypography
                style={{ fontSize: 13, fontWeight: 500 }}
                primary={item}
              />
            </ListItem>
          ))}
        </List>
      </TabPanel>
      <TabPanel value={value} index={10}>
        <List component="li">
          {child.map((item, i) => (
            <ListItem key={i} button>
              <ListItemIcon>
                <Checkbox />
              </ListItemIcon>
              <ListItemText
                disableTypography
                style={{ fontSize: 13, fontWeight: 500 }}
                primary={item}
              />
            </ListItem>
          ))}
        </List>
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
    </div>
  );
}
