import {
  useMediaQuery,
  useTheme,
  Grid,
  Box,
  CircularProgress,
} from "@material-ui/core";
// import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import Card from "../Components/Card/Card";
import { fetchProducts } from "../hooks/useProducts";
import HeavyProductCard from "../Products/HeavyProductCard";

const ShowCaseProducts = ({ num }) => {
  const { categoryId } = useParams();
  // const [sortBy, setSortBy] = useState("");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  // useEffect(() => {
  //   num && num === 0 && setSortBy("popularity");
  //   num && num === 1 && setSortBy("lowToHigh");
  //   num && num === 2 && setSortBy("highToLow");
  //   num && num === 3 && setSortBy("newestFirst");
  //   num && sortBy && console.log(num, sortBy);
  //   return () => setSortBy(sortBy);
  // }, [num, sortBy]);

  const { data, isLoading } = useQuery({
    queryKey: ["products", categoryId],
    queryFn: fetchProducts,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  // console.log(data);
  // const { isLoading, data } = useQuery("products", () =>
  //   fetchd(categoryId, sortBy)
  // );
  return (
    <>
      <Grid container justify="center">
        {isLoading ? (
          <Loading />
        ) : (
          <Grid
            container
            className="grayBorderBottom"
            style={{ background: "white" }}
          >
            {data?.map((item, i) =>
              item?.type === "big" ? (
                <HeavyProductCard item={item} key={item._id} />
              ) : (
                <Grid
                  key={item._id}
                  item
                  lg={3}
                  xs={6}
                  className={matches ? "smallImageBorder" : ""}
                  component={Link}
                  to={`/product/${item._id}`}
                >
                  <Card
                    image={item.images}
                    name={item.name}
                    rating={item.rating}
                    brand={item.brand}
                    price={item.cost}
                    actualPrice={item.actualPrice}
                    offerPrice={item.offerPrice}
                    discount={item.discount}
                  />
                </Grid>
              )
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default ShowCaseProducts;

export const Loading = () => {
  return (
    <Box
      position="absolute"
      left={0}
      top={0}
      bottom={0}
      right={0}
      style={{ background: "rgba(0,0,0,0.5)" }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <CircularProgress thickness={5} style={{ width: 200, height: 200 }} />
      </Box>
    </Box>
  );
};
