import { Grid, Hidden } from "@material-ui/core";
import { CardGiftcard } from "@material-ui/icons";

const Footer = () => {
  return (
    <>
      <div
        style={{
          background: "#172337",
          color: "white",
        }}
      >
        <Grid
          container
          style={{
            fontSize: 12,
            padding: "50px 60px",
            borderBottom: "1px solid rgba(255,255,255,0.5)",
          }}
        >
          <Grid item lg={7} xs={12}>
            <Grid container>
              <FooterItem col={3} xs={6} data={about} heading="ABOUT" />
              <FooterItem col={3} xs={6} data={help} heading="HELP" />
              <FooterItem col={3} xs={6} data={policy} heading="POLICY" />
              <FooterItem col={3} xs={6} data={social} heading="SOCIAL" />
            </Grid>
          </Grid>
          <Grid item lg={5} xs={12}>
            <Grid container>
              <FooterItem
                col={6}
                xs={12}
                data={regis}
                heading="Registered Office Address:"
              />
              <FooterItem col={6} xs={12} data={mail} heading="Mail Us:" />
            </Grid>
          </Grid>
        </Grid>
        <Hidden xsDown>
          <Grid container justify="space-around" style={{ padding: "25px 0" }}>
            {Array(4)
              .fill("")
              .map((_, i) => (
                <div className="flexStyle" key={i}>
                  <CardGiftcard
                    style={{ color: "#FFB800", marginRight: 7, fontSize: 20 }}
                  />
                  <p>GiftCard</p>
                </div>
              ))}
            <p>© 2007-2021 Flipkart.com</p>
            <div className="flexStyle">
              {Array(10)
                .fill("")
                .map((_, i) => (
                  <div
                    key={i}
                    style={{
                      width: 35,
                      height: 17,
                      background: "white",
                      borderRadius: 3,
                      margin: 4,
                    }}
                  ></div>
                ))}
            </div>
          </Grid>
        </Hidden>
      </div>
    </>
  );
};

export default Footer;

const FooterItem = ({ heading, data, col, xs }) => {
  return (
    <Grid item lg={col} xs={xs}>
      <p style={{ color: "#878787", paddingBottom: "5px" }}>{heading}</p>
      {data.map((item) => (
        <p key={item} style={{ padding: "5px 0" }}>
          {item}
        </p>
      ))}
    </Grid>
  );
};

const about = [
  "Contact Us",
  "About Us",
  "Careers",
  "Flipkart Stories",
  "Press",
  "Flipkart Wholesale",
];
const help = [
  "Payments",
  "Shipping",
  "Cancellation & Returns",
  "FAQ",
  "Report",
  "Infringement",
];
const policy = [
  "Return Policy",
  "Terms Of Use",
  "Security",
  "Privacy",
  "Sitemap",
  "EPR Compliance",
];
const social = ["Facebook", "Twitter", "YouTube"];
const mail = [
  "Flipkart Internet Private Limited",
  "Buildings Alyssa, Begonia &",
  "Clove Embassy Tech Village",
  "Outer Ring Road, Devarabeesanahalli Village",
  "Bengaluru, 560103",
  "Karnataka, India",
];
const regis = [
  "Flipkart Internet Private Limited",
  "Buildings Alyssa, Begonia &",
  "Clove Embassy Tech Village",
  "Outer Ring Road, Devarabeesanahalli Village",
  "Bengaluru, 560103",
  "Karnataka, India",
];
