import {
  Box,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import {
  CheckCircle,
  ExpandMore,
  Star,
  ThumbDown,
  ThumbUp,
} from "@material-ui/icons";
import multicar6 from "./assets/multicar6.jpeg";
import multicar7 from "./assets/multicar7.jpeg";

const useStyles = makeStyles({
  ratingHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 78,
    padding: "15px",
  },
  ratingButton: {
    textTransform: "capitalize",
    height: 50,
    width: 150,
    fontWeight: 600,
    boxShadow: "0 1px 2px 0 rgb(0 0 0 / 26%)",
  },
  thumbs: {
    display: "flex",
    alignItems: "center",
    color: "gray",
    justifyContent: "space-between",
    fontSize: 13,
  },
});
const RatRev = () => {
  const classes = useStyles();

  return (
    <>
      <div
        className="grayBorderBottom"
        style={{
          background: "white",
        }}
      >
        <div className={classes.ratingHeader}>
          <h1 style={{ fontSize: "clamp(1rem,2vw,1.8rem)" }}>
            Ratings & Reviews
          </h1>
          <Button className={classes.ratingButton}>Rate product</Button>
        </div>
        <Grid
          container
          alignItems="center"
          justify="space-around"
          className="flexStyle"
          style={{ padding: 15, justifyContent: "space-around" }}
        >
          <Grid
            item
            lg={6}
            xs={12}
            style={{ textAlign: "center", display: "flex", marginBottom: 25 }}
          >
            <Grid container direction="column">
              <p style={{ fontSize: 32, fontWeight: 500 }}>4.4★</p>
              <p style={{ color: "#878787" }}>20,781 Ratings &</p>
              <p style={{ color: "#878787" }}>3,294 Reviews</p>
            </Grid>
            <Grid container>
              <CusProgress value={20} color="#388E3C" star={1} number="7,071" />
              <CusProgress
                value={10}
                color="#388E3C"
                star={2}
                number="70,701"
              />
              <CusProgress value={5} color="#388E3C" star={3} number={1} />
              <CusProgress value={50} color="#FF9F00" star={4} number={70} />
              <CusProgress value={70} color="#FF6161" star={5} number={700} />
            </Grid>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Grid container justify="space-around">
              <CusCircularProgress color="#388E3C" value={10} />
              <CusCircularProgress color="#FF9F00" value={30} />
              <CusCircularProgress color="#FF6161" value={60} />
            </Grid>
          </Grid>
        </Grid>
        <div style={{ padding: 15 }}>
          <img
            src={multicar6}
            alt=""
            width="70"
            height="70"
            style={{ objectFit: "contain", margin: 5, padding: 3 }}
            className="grayBorder"
          />
          <img
            src={multicar7}
            alt=""
            width="70"
            height="70"
            style={{ objectFit: "contain", margin: 5, padding: 3 }}
            className="grayBorder"
          />
          <img
            src={multicar6}
            alt=""
            width="70"
            height="70"
            style={{ objectFit: "contain", margin: 5, padding: 3 }}
            className="grayBorder"
          />
          <img
            src={multicar7}
            alt=""
            width="70"
            height="70"
            style={{ objectFit: "contain", margin: 5, padding: 3 }}
            className="grayBorder"
          />
          <img
            src={multicar6}
            alt=""
            width="70"
            height="70"
            style={{ objectFit: "contain", margin: 5, padding: 3 }}
            className="grayBorder"
          />
          <img
            src={multicar7}
            alt=""
            width="70"
            height="70"
            style={{ objectFit: "contain", margin: 5, padding: 3 }}
            className="grayBorder"
          />
        </div>
      </div>
      <RatRevOpi />
      <RatRevOpi />
    </>
  );
};

export default RatRev;
export const RatRevOpi = () => {
  const classes = useStyles();
  return (
    <div
      style={{ padding: 15, background: "white" }}
      className="grayBorderBottom"
    >
      <div className="flexStyle" style={{ padding: 10, paddingBottom: 0 }}>
        <div
          style={{
            background: "#26A541",
            color: "white",
            fontSize: 13,
            padding: "2px 4px",
            borderRadius: 4,
            width: "fit-content",
            marginRight: 10,
          }}
        >
          <span>4.4</span>
          <Star style={{ fontSize: 13 }} />
        </div>
        <p style={{ fontWeight: 500 }}>Excellent</p>
      </div>
      <p style={{ padding: 10, fontSize: 14 }}>
        product really great . I am totally sctified this product .really good
        camera for beggner .but flash button was missing that is not good .and
        capture is 18 mp wasn't good I was hopeed it will be 24 mp . it is also
        missing .n everything is good .
      </p>
      <img
        src={multicar7}
        alt=""
        width="70"
        height="70"
        style={{ objectFit: "contain", margin: 5, padding: 3 }}
        className="grayBorder"
      />
      <img
        src={multicar7}
        alt=""
        width="70"
        height="70"
        style={{ objectFit: "contain", margin: 5, padding: 3 }}
        className="grayBorder"
      />
      <div className={classes.thumbs}>
        <div style={{ flex: 0.85 }}>
          <div className="flexStyle">
            <p>Certified Buyer</p>
            <CheckCircle style={{ fontSize: 17 }} />
            <p>Certified Buyer, Tahliwala Industrial Area Oct, 2019</p>
          </div>
        </div>
        <div
          style={{
            flex: 0.15,
            justifyContent: "space-around",
            display: "flex",
          }}
        >
          <ThumbUp /> <span>1025</span>
          <ThumbDown /> <span>246</span>
          <ExpandMore />
        </div>
      </div>
    </div>
  );
};

const CusProgress = ({ number, star, color, value }) => {
  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 5,
      borderRadius: 5,
      maxWidth: 200,
      minWidth: 150,
    },
    colorPrimary: {
      backgroundColor: "#F0F0F0",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: color,
    },
  }))(LinearProgress);
  return (
    <div className="flexStyle">
      <p style={{ marginRight: 7, fontSize: 13.5 }}>{star}★</p>
      <BorderLinearProgress variant="determinate" value={value} />
      <p style={{ marginLeft: 17, fontSize: 13.5, color: "var(--gray)" }}>
        {number}
      </p>
    </div>
  );
};

function CusCircularProgress({ color, value }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      position: "relative",
      display: "inline-flex",
      margin: 10,
    },
    bottom: {
      color: "#f0f0f0",
    },
    top: {
      color: color,
      animationDuration: "550ms",
      position: "absolute",
      left: 0,
    },
    circle: {
      strokeLinecap: "round",
    },
  }));
  const classes = useStyles();

  return (
    <div className="flexStyle" style={{ flexDirection: "column" }}>
      <div className={classes.root}>
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <p style={{ fontSize: 24, fontWeight: 500 }}>70</p>
        </Box>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={88}
          thickness={4}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          className={classes.top}
          classes={{
            circle: classes.circle,
          }}
          size={88}
          thickness={4}
          value={value}
        />
      </div>
      <p style={{ fontSize: 14, fontWeight: 500 }}>chikki</p>
    </div>
  );
}
