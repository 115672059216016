import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.css";
// import { multiData } from "../data";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { Link } from "react-router-dom";
// import { useMultiItemCarousels } from "../hooks/useProducts";
// import axios from "axios";
import React from "react";
import { generateImageUrl } from "../config";

const PreviousBtn = (props) => {
  // console.log(props);
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowBackIos style={{ color: "blue", fontSize: "30px" }} />
    </div>
  );
};
const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowForwardIos style={{ color: "blue", fontSize: "30px" }} />
    </div>
  );
};

const settings = {
  nextArrow: <NextBtn />,
  prevArrow: <PreviousBtn />,
  responsive: [
    {
      breakpoint: 1030,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const MultiItemCarousel = ({ slides, matches, data }) => {
  console.log("multiItemCarousel Component");

  return (
    // <div
    //   style={{
    //     margin: 10,
    //     boxShadow: "0 2px 4px 0 rgb(0 0 0 / 8%)",
    //     background: "white",
    //   }}
    // >
    <div
      className={`homePage__carousel ${
        matches ? "multiItemProductCarouselXs" : "multiItemProductCarousel"
      }`}
    >
      <div className="carousel__header flexStyle">
        <h1>{data?.carousels[0].heading}</h1>
        <button>view all</button>
      </div>
      <Slider {...settings} slidesToShow={slides || 4} slidesToScroll={4}>
        {data?.carousels?.map((item) => (
          <Card
            image={item.carouselImage}
            key={item._id}
            path={item.category}
            name={item.name}
          />
        ))}
      </Slider>
    </div>
    // </div>
  );
};

const Card = ({ image, path, name }) => {
  return (
    <Link to={`/products/${name}/${path}`}>
      <div style={{ textAlign: "center", margin: "30px 0" }}>
        <img
          className="multi__image"
          src={generateImageUrl(image)}
          alt=""
          style={{
            width: "100%",
            height: "140px",
            objectFit: "contain",
            marginBottom: "10px",
          }}
        />
        <p style={{ fontSize: "14px", padding: "5px 0", fontWeight: 500 }}>
          {name}
        </p>
        <p style={{ fontSize: "16px", padding: "5px 0", color: "green" }}>
          From ₹ 7,000
        </p>
        <p style={{ fontSize: "14px", padding: "5px 0", color: "gray" }}>
          Up To ₹ 5,000 Off on HDFC
        </p>
      </div>
    </Link>
  );
};

export default React.memo(MultiItemCarousel);
